<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="$emit('modalShown');initData()"
            size="xl"
            class="invoice-modal"
    >
        <template #modal-title>
            {{$t('label_generate_invoice')}}
        </template>

        <template #default="{ hide }">

        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >
<b-row>
    <b-col cols="12" md="3">
        <validation-provider
                #default="validationProps"
                :name="$t('label_invoice_number')"
                rules="required"
                slim
                vid="invoice_no"
        >
            <b-form-group
                    :label="$t('label_invoice_number')"
            >
                <b-form-input
                        v-model="itemData.invoice_no"
                        :state="getValidationState(validationProps)"
                        :placeholder="$t('label_invoice_number')"
                />

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
        </validation-provider>
    </b-col>
    <b-col cols="12" md="3">

        <validation-provider
                #default="validationProps"
                :name="$t('label_date_of_invoice')"
                rules="required"
        >
            <b-form-group
                    :label="$t('label_date_of_invoice')"
                    class=""
                    :state="getValidationState(validationProps)"
            >

                <custom-date-picker
                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                        :locale="$store.state.appConfig.locale"
                        :placeholder="$t('label_DD-MM-YYYY')"
                        :value="itemData.date_of_invoice"

                        @input="itemData.date_of_invoice = $event"
                >
                    <template #clear-btn="{ vm }">
                        <feather-icon icon="XIcon" size="14"/>
                    </template>
                    <div slot="label">
                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                    </div>
                </custom-date-picker>

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
        </validation-provider>
    </b-col>
    <b-col cols="12" md="3">
        <!--<validation-provider-->
                <!--#default="validationProps"-->
                <!--:name="$t('label_date_of_selling')"-->
                <!--rules="required|numeric"-->
                <!--slim-->
        <!--&gt;-->
            <!--<b-form-group-->
                    <!--:label="$t('label_date_of_selling')"-->
            <!--&gt;-->
                <!--<b-form-input-->
                        <!--required-->
                        <!--v-model="itemData.date_of_selling"-->
                        <!--:state="getValidationState(validationProps)"-->
                        <!--:placeholder="$t('label_date_of_selling')"-->
                <!--/>-->

                <!--<b-form-invalid-feedback :state="getValidationState(validationProps)">-->
                    <!--{{ validationProps.errors[0] }}-->
                <!--</b-form-invalid-feedback>-->
            <!--</b-form-group>-->
        <!--</validation-provider>-->
        <validation-provider
                #default="validationProps"
                :name="$t('label_date_of_selling')"
                rules="required"
        >
            <b-form-group
                    :label="$t('label_date_of_selling')"
                    class=""
                    :state="getValidationState(validationProps)"
            >

                <custom-date-picker
                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                        :locale="$store.state.appConfig.locale"
                        :placeholder="$t('label_DD-MM-YYYY')"
                        :value="itemData.date_of_selling"

                        @input="itemData.date_of_selling = $event"
                >
                    <template #clear-btn="{ vm }">
                        <feather-icon icon="XIcon" size="14"/>
                    </template>
                    <div slot="label">
                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                    </div>
                </custom-date-picker>

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
        </validation-provider>
    </b-col>
    <b-col cols="12" md="3">
        <validation-provider
                #default="validationProps"
                :name="$t('label_place_of_invoice')"
                rules="required"
                slim
        >
            <b-form-group
                    :label="$t('label_place_of_invoice')"
            >
                <b-form-input
                        required
                        v-model="itemData.place_of_invoice"
                        :state="getValidationState(validationProps)"
                        :placeholder="$t('label_place_of_invoice')"
                />

                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                    {{ validationProps.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
        </validation-provider>
    </b-col>

</b-row>
            <hr>

            <b-row>
                <b-col cols="12" md="6">
                    <div><h5 class=" mt-1"><strong>{{$t('label_seller')}}</strong></h5></div>
                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_name')}}: </strong>
                        <span class="m-l-5">{{client.name}}</span>
                    </p>
                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_address')}}:</strong>
                        <span class="m-l-5">
                                {{client.agent_data && client.agent_data.street ? client.agent_data.street + ' ' : ''}}
                                {{client.agent_data && client.agent_data.zip ? client.agent_data.zip + ' ' : ''}}
                                {{client.agent_data && client.agent_data.town ? client.agent_data.town  : ''}}
                            </span>
                    </p>
                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_nip')}}: </strong>
                        <span class="m-l-5">{{client.agent_data && client.agent_data.NIP? client.agent_data.NIP : ''}}</span>
                    </p>

                    <p v-if="client.KRS" class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_krs')}}: </strong>
                        <span class="m-l-5">{{client.KRS}}</span>
                    </p>

                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_vat_payer')}}: </strong>
                        <span class="m-l-5">{{client.agent_data && client.agent_data.vat_payer == 'Y'? $t('label_yes') : $t('label_no')}}</span>
                    </p>

                    <p v-if="client.agent_data && client.agent_data.account_number" class="text font-13 m-0">
                        <strong style="font-weight: 500;line-height: 26px">{{$t('label_account_number')}}: </strong>
                        <span class="m-l-5">{{client.agent_data && client.agent_data.account_number}}</span>
                    </p>

                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500;line-height: 26px">{{$t('label_payment_type')}}: </strong>
                        <span class="m-l-5">{{$t('label_bank_transfer')}}</span>
                    </p>
                </b-col>

                <b-col cols="12" md="6">

                    <div><h5 class=" mt-1"><strong>{{$t('label_buyer')}}</strong></h5></div>
                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_name')}}: </strong>
                        <span class="m-l-5">{{owner_invoice_data.company}}</span>
                    </p>
                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_address')}}: </strong>
                        <span class="m-l-5">{{owner_invoice_data.address}}</span>
                    </p>
                    <p class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_nip')}}: </strong>
                        <span class="m-l-5">{{owner_invoice_data.nip? owner_invoice_data.nip : ''}}</span>
                    </p>

                    <p v-if="owner_invoice_data.krs" class="text font-13 m-0">
                        <strong style="font-weight: 500; line-height: 26px">{{$t('label_krs')}}: </strong>
                        <span class="m-l-5">{{owner_invoice_data.krs}}</span>
                    </p>

                    <p v-if="owner_invoice_data.account_number" class="text font-13 m-0">
                        <strong style="font-weight: 500;line-height: 26px">{{$t('label_account_number')}}: </strong>
                        <span class="m-l-5">{{owner_invoice_data.account_number}}</span>
                    </p>



                </b-col>


            </b-row>
            <br>
            <br>
            <!--<hr>-->

            <b-table-simple class="table-details table-prov-details" responsive no-border-collapse>
                    <dynamic-items-block
                            tag="tbody"
                            :no-switch="true"
                            :show-items-section="false"
                            class="mt-2"
                            alias="provision"
                            label="label_provision_general"
                            :blank-item="{formula: '', net_price: '', vat: '', gross_price: ''}"
                            :new-items.sync="new_provisions"
                    >


                        <template #prepend_content="prepend_content">
                            <b-tr>
                                <b-th width="10px">{{$t('label_#')}}</b-th>
                                <b-th>{{$t('label_name_of_service')}}</b-th>
                                <b-th>{{$t('label_quantity')}}</b-th>
                                <b-th>{{$t('label_net_price')}}</b-th>
                                <b-th>{{$t('label_vat')}}</b-th>
                                <b-th>{{$t('label_gross_price')}}</b-th>
                                <b-th>{{$t('label_action')}}</b-th>
                            </b-tr>
                            <b-tr :key="key" v-for="provision, key in due_law_provisions">
                                <b-td>{{key + 1}}</b-td>
                                <b-td>
                                    <validation-provider
                                            #default="validationProps"
                                            :name="$t('label_invoice_number')"
                                            rules="required"
                                            slim
                                    >
                                        <b-form-group

                                        >
                                            <b-form-input
                                                    required
                                                    v-model="provision.formula"
                                                    :state="getValidationState(validationProps)"

                                            />

                                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                                {{ validationProps.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-td>
                                <b-td>1</b-td>
                                <b-td>
                                    <b-form-input
                                            disabled
                                            required
                                            :value="provision.net_price?  provision.net_price.formatPrice() : ''"

                                    />
                                </b-td>
                                <b-td>
                                    <b-form-input
                                            disabled
                                            required
                                            :value="provision.vat ? provision.vat.formatPrice() : (client.agent_data.vat_payer == 'Y' ? $t('label_vat') : 'ZW')"

                                    />
                                </b-td>
                                <b-td>
                                    <b-form-input
                                            disabled
                                            required
                                            :value="provision.gross_price? provision.gross_price.formatPrice() : ''"

                                    />
                                </b-td>
                                <b-td class="actions"></b-td>
                            </b-tr>
                        </template>

                        <template #button="{addBlankItem}">
                            <tr >
                                <td colspan="7" class="text-left">
                                    <b-button @click.prevent="addBlankItem"
                                              class=" btn btn-success  ">
                                        {{$t('label_new')}}
                                    </b-button>
                                </td>
                            </tr>
                        </template>

                        <template #form="{item, ind, deleteItem}">



                                <b-td :key="ind">{{due_law_provisions.length + ind + 1}}</b-td>
                                <b-td>
                                    <validation-provider
                                            #default="validationProps"
                                            :name="$t('label_invoice_number')"
                                            rules="required"
                                            slim
                                            :vid="'provision_' + (item.id? '__' + item.id :  ind)"
                                    >
                                        <b-form-group

                                        >
                                            <b-form-input
                                                    required
                                                    v-model="item.formula"
                                                    :state="getValidationState(validationProps)"
                                                    :placeholder="$t('label_invoice_number')"
                                            />

                                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                                {{ validationProps.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-td>
                                <b-td>1</b-td>
                                <b-td>
                                    <validation-provider
                                            #default="validationProps"
                                            :name="$t('label_net_price')"
                                            rules="required|price"
                                            slim
                                            :vid="'net_price_' + (item.id? '__' + item.id :  ind)"
                                    >
                                        <b-form-group

                                        >
                                    <b-form-input
                                            class="price-input"
                                            v-model="item.net_price"
                                            @input="calcVatAndGrossPrice(item)"

                                    />
                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-td>
                                <b-td>
                                    <b-form-input
                                            class="price-input"
                                            disabled
                                            required
                                            v-model="item.vat.formatPrice()"

                                    />
                                </b-td>
                                <b-td>
                                    <b-form-input
                                            class="price-input"
                                            disabled
                                            required
                                            v-model="item.gross_price.formatPrice()"

                                    />
                                </b-td>
                                <b-td class="actions">
                                    <button @click="deleteItem()"
                                            :style="{top: ind>0? 'calc(50% - 8px)' : 'calc(50% - 20px)'}"
                                            style=" position: absolute;;cursor:pointer; right: 0px;z-index: 10;"
                                            class="remove-procurator-item btn  "
                                            type="button">
                                        <feather-icon color="red" title="Clear" data-toggle icon="XIcon" size="25"/>
                                    </button>
                                </b-td>


                        </template>

                            <!--</b-tbody>-->
                        <!---->
                    </dynamic-items-block>
            </b-table-simple>

            <hr>

            <b-row>
                <b-col cols="12" md="4">
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_payment_type')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_payment_type')"
                        >
                            <b-form-input
                                    required
                                    v-model="itemData.pament_type"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_payment_type')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12" md="4">

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_term_of_payment')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_term_of_payment')"
                                class=""
                                :state="getValidationState(validationProps)"
                        >

                            <custom-date-picker

                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"
                                    :value="itemData.term_of_payment"

                                    @input="itemData.term_of_payment = $event"
                            >
                                <template #clear-btn="{ vm }">
                                    <feather-icon icon="XIcon" size="14"/>
                                </template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>

                <b-col cols="12" md="4">
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_currency')"
                            rules="required"
                            slim
                    >

                        <b-form-group
                                :label="$t('label_currency')"
                                class=""
                                :state="getValidationState(validationProps)"
                        >
                        <v-select
                                v-model="itemData.currency"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="[{label:'PLN', value: 'PLN'},{label:'NOK', value: 'NOK'}, {label:'EUR', value: 'EUR'}, {label:'GBR', value: 'GBR'}]"
                                :reduce="val => val.value"
                                label="label"
                                :clearable="false"
                                :placeholder="$t('label_currency')"
                        >
                            <template v-slot:option="option">
                                {{option.label}}
                            </template>
                            <template v-slot:selected-option="option">
                                {{option.label}}
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>

                    </validation-provider>
                </b-col>

            </b-row>

            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_add')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
         BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
        BTableSimple, BTh, BTbody, BTd, BTr, BButton
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'
    import infinityScroll from '@/views/components/infinityScroll'
    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import vSelect from 'vue-select'
    import {serialize} from 'object-to-formdata';
    import { INVOICE_PREFIX as PREFIX, MODULE_PREFIX, PAYMENT_PREFIX} from './../moduleHelper'

    export default {
        components: {

             BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend,
            BTableSimple, BTh, BTbody, BTd, BTr, BButton,

            ValidationProvider,
            ValidationObserver,
            infinityScroll,
            fileInput,
            vSelect,
            dynamicItemsBlock,
        },
        props:['editedItem', 'client'],
        data() {
            return {

                MODULE_PREFIX,
                PAYMENT_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {
                    agent_id:this.authUser().id,
                    invoice_no: '',
                    date_of_invoice:this.$moment().format('DD-MM-YYYY'),
                    date_of_selling:this.$moment().format('DD-MM-YYYY'),
                    place_of_invoice:'',
                    pament_type: 'Przelew bankowy',
                    term_of_payment:this.$moment().add(7, 'days').format('DD-MM-YYYY'),
                    currency: 'PLN',
                    payments:[]
                },

                itemData: {},
                due_law_provisions:[],
                new_provisions:[],
                owner_invoice_data: {}

            }
        },

        methods: {
            initData(){

                if(this.editedItem){

                } else {
                    this.action = 'adding';

                    this.itemData =  Object.assign({},this.blankItemData);

// console.log('client', this.client);
                    // switch(this.provisionType){
                    //     case 'general_provision': this.itemData.type = 1; break;
                    //     case 'court_provision': this.itemData.type = 2;break;
                    //     case 'central_provision': this.itemData.type = 3;break;
                    //     case 'upper_provision': this.itemData.type = 4;break;
                    // }


                }

                this.async('get', '/due_law_provisions' , {params:{status: 'notpaid', start: 0, length: 10000, agent_id: this.client.id}}, function(res){
                    let items = [];
                    for(let i = 0; i<res.data.items.length; i++) {
                        let item = res.data.items[i];
                        this.calcPrices(item);
                        item.formula = this.$t(item.formula);
                        // console.log('s',item);
                        items.push(item);
                        this.due_law_provisions = items;
                    }

                });
                this.async('get', '/invoice_details', {params:{}}, function(res){
                    // this.itemData = Object.assign({}, this.blankItemData);
                    // let editedItem = res.data[0];
                    // let item = Object.assign({}, this.blankItemData);
                    // if(typeof editedItem == 'object') {
                    //     item.id = editedItem.id;
                    //     for (let prop in item) {
                    //         if (editedItem.hasOwnProperty(prop)) {
                    //             item[prop] = editedItem[prop];
                    //         }
                    //     }
                    // }

                    this.owner_invoice_data = res.data[0];
                    // console.log(this.itemData);
                });

            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);

                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){
                // console.log('this.due_law_provisions',this.due_law_provisions);
                // console.log('this.new_provisions',this.new_provisions);
                let payments = [];
                for(let i = 0; i < this.due_law_provisions.length; i++ ){
                    let item = {};
                    item.service_name = this.due_law_provisions[i].formula;
                    item.net_price = this.due_law_provisions[i].net_price.formatPrice().replace(',', '.');
                    item.vat =  this.due_law_provisions[i].vat.formatPrice().replace(',', '.');
                    item.gross_price = this.due_law_provisions[i].gross_price.formatPrice().replace(',', '.');
                    item.service_name = this.due_law_provisions[i].formula;
                    item.type = 'due_law_provision';
                    item.source_id = this.due_law_provisions[i].id;
                    payments.push(item);
                }
                for(let j = 0; j < this.new_provisions.length; j++ ){
                    let item = {};
                    item.service_name = this.new_provisions[j].formula;
                    item.net_price = this.new_provisions[j].net_price.formatPrice().replace(',', '.');
                    item.vat =  this.new_provisions[j].vat.formatPrice().replace(',', '.');
                    item.gross_price = this.new_provisions[j].gross_price.formatPrice().replace(',', '.');
                    item.service_name = this.new_provisions[j].formula;
                    item.type = '';
                    item.source_id = '';
                    payments.push(item);
                }
                this.itemData.payments = payments;
                // console.log('this.itemData',this.itemData);
                // throw 1;
                if (this.action == 'adding') {
                    this.async('post', '/agent_invoice', this.itemData, function (resp) {
                        this.$emit('item-added', resp.data.item);
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });``

                } else {

                }

            },
            calcPrices(item){
                if(this.client.agent_data.company=='Y' && this.client.agent_data.vat_payer=='Y'){
                    item.net_price = (item.porper_provision_value/1.23);
                    item.vat = (item.net_price*0.23);
                    item.gross_price = (item.porper_provision_value);
                } else if(this.client.agent_data.vat_payer=='Y') {
                    item.net_price = (((item.porper_provision_value*item.porper_provision_value)/100)/1.23);
                    item.vat = (item.net_price*0.23);
                    item.gross_price = (item.net_price+item.vat);
                } else {
                    item.net_price = item.porper_provision_value;
                    item.vat = 0;
                    item.gross_price = item.porper_provision_value;
                }
            },
            calcVatAndGrossPrice(item){
                let net_price = parseFloat(item.net_price.replace(',', '.'));
                if(this.client.agent_data.company=='Y' && this.client.agent_data.vat_payer=='Y'){
                    item.vat = (net_price*0.23);
                    item.gross_price = parseFloat(net_price) + parseFloat(item.vat);
                } else if(this.client.agent_data.vat_payer=='Y') {
                    item.vat = (net_price*0.23);
                    item.gross_price = parseFloat(net_price) + parseFloat(item.vat);
                } else {
                    item.vat = 0;
                    item.gross_price = parseFloat(net_price) + parseFloat(item.vat);
                }
            }
        },


    }
</script>

<style scoped>
    /*@media (min-width: 992px){*/

    /*}*/
    .table-prov-details td .form-group{
        margin-bottom: 0;
    }
</style>