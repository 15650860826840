<template>

    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{$t('label_add_invoice')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        class="mt-2"
                        :name="$t('label_document')"
                        slim
                        #default="validationProps"
                        :rules="editedItem? '' : 'required'"
                >
                    <file-input
                            v-model="document"
                            class="file--uploader size--big drop-block "
                            :max-files-count='1'
                            :multiple="false"
                            :allowed-extensions="false"
                            :max-file-size='15'
                            :url="''"
                    >
                        <template #uploadBody>
                            <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                            <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                            <span>{{$t('label_add_file')}}</span>
                        </template>

                        <template #errorBug="fileErrors">

                            <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>

                        </template>
                    </file-input>
                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>

                </validation-provider>
                <b-progress v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>
                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
        BFormGroup, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BFormInput, BProgress
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import fileInput from '@/views/components/fileUploadInput'

    import { MODULE_PREFIX} from './../moduleHelper'

    import {serialize} from 'object-to-formdata';

    export default {
        components: {

            BFormGroup, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormInput, BProgress,

            ValidationProvider,
            ValidationObserver,
            fileInput,
            vSelect

        },

        props: ['client', 'editedItem', 'docType', 'itemId'],

        data() {
            return {

                MODULE_PREFIX,
                PREFIX: 'upload-doc',

                action: 'adding',

                document: [],

                itemData: false

            }
        },

        methods: {
            initData() {

                if (this.editedItem) {

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData)
                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);

                if (this.document.length > 0) {
                    formData.append('document[]', this.document[0], this.document[0].name);
                }

                let url = ''; let method = '';
                if(this.docType == 'invoice'){
                    method = 'post';
                    url = '/agent_invoice/document/';
                    formData.append('agent_id',  this.client.id );
                } else if(this.docType == 'admin_bill') {
                    method = 'put';
                    url = '/agent_invoice/document/' + this.itemId;
                    formData.append('type', 'admin');
                } else if(this.docType == 'agent_bill') {
                    method = 'put';
                    url = '/agent_invoice/document/' + this.itemId;
                    formData.append('type', 'agent');
                }
                if (this.action == 'adding') {
                    this.async(method, url, formData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide('upload-doc-modal');
                    }, false, {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                }


            }
        },

    }
</script>